import React, { useState, useEffect } from 'react';
import { AlertCircle, Copy, CheckCircle } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from './ui/Alert';
import { Button } from './ui/Button';
import { Input } from './ui/Input';
import { Label } from './ui/Label';
import { Textarea } from './ui/Textarea';
import { Card } from './ui/Card';
import { Checkbox } from './ui/Checkbox';
import { EmbedVisualizer } from 'embed-visualizer';
import 'embed-visualizer/dist/index.css';
import yaml from 'js-yaml';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

const FieldInput = ({ index, field, updateField, removeField }) => (
  <div className="space-y-2">
    <Input
      placeholder="Name"
      value={field.name}
      onChange={(e) => updateField(index, { ...field, name: e.target.value })}
    />
    <Input
      placeholder="Value"
      value={field.value}
      onChange={(e) => updateField(index, { ...field, value: e.target.value })}
    />
    <div className="flex items-center space-x-2">
      <Checkbox
        id={`inline-${index}`}
        checked={field.inline}
        onCheckedChange={(checked) => updateField(index, { ...field, inline: checked })}
      />
      <Label htmlFor={`inline-${index}`}>Inline</Label>
    </div>
    <Button onClick={() => removeField(index)} variant="destructive" size="sm">
      Remove Field
    </Button>
  </div>
);

const defaultEmbed = {
  color: 5814783,
  title: '',
  description: '[message]',
  author: { name: '', icon_url: '', url: '' },
  thumbnail: { url: '' },
  fields: [],
  image: { url: '' },
  footer: {
    text: 'This is an AI generated response. Some information may be inaccurate.',
    icon_url: 'https://i.ibb.co/5YFpYWD/image.png'
  }
};

const defaultInteractionMessage = {
  color: 3447003,
  title: 'Bot Interaction',
  url: 'https://code-solutions.dev',
  author: { name: '', icon_url: '', url: '' },
  thumbnail: { url: 'https://api.creepernation.net/avatar/[player.name]' },
  fields: [
    { name: "Player", value: "[player.name]", inline: true },
    { name: "Sent", value: "[sent]", inline: true },
    { name: "Type", value: "[type]", inline: true },
    { name: "Player's Question", value: "[question]" },
    { name: "Bot's Response", value: "[response]" }
  ],
  image: { url: '' },
  footer: { 
    text: 'ServerAssistantAI',
    icon_url: 'https://code-solutions.dev/assets/images/SAAI-logo.png'
  }
};

const DiscordEmbedCreator = () => {
  const [activeTab, setActiveTab] = useState('replyFormat');
  const [replyEmbed, setReplyEmbed] = useState(defaultEmbed);
  const [interactionMessage, setInteractionMessage] = useState(defaultInteractionMessage);
  const [output, setOutput] = useState('');
  const [previewKey, setPreviewKey] = useState(0);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setOutput('');
    setPreviewKey(prev => prev + 1);
  }, [activeTab]);

  const cleanObject = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(cleanObject).filter(item => {
        if (typeof item === 'object' && item !== null) {
          return Object.keys(item).length > 0;
        }
        return item !== '' && item !== null && item !== undefined;
      });
    }
    
    const cleaned = { ...obj };
    Object.keys(cleaned).forEach(key => {
      if (typeof cleaned[key] === 'object' && cleaned[key] !== null) {
        cleaned[key] = cleanObject(cleaned[key]);
        if (Array.isArray(cleaned[key])) {
          if (cleaned[key].length === 0) {
            delete cleaned[key];
          }
        } else if (Object.keys(cleaned[key]).length === 0) {
          delete cleaned[key];
        }
      } else if (cleaned[key] === '' || cleaned[key] === null || cleaned[key] === undefined) {
        delete cleaned[key];
      }
    });

    if (cleaned.author && !cleaned.author.name) {
      delete cleaned.author;
    }

    return cleaned;
  };

  const updateEmbed = (key, value) => {
    if (activeTab === 'replyFormat') {
      setReplyEmbed(prev => ({ ...prev, [key]: value }));
    } else {
      setInteractionMessage(prev => ({ ...prev, [key]: value }));
    }
  };

  const updateNestedEmbed = (parent, key, value) => {
    if (activeTab === 'replyFormat') {
      setReplyEmbed(prev => ({
        ...prev,
        [parent]: { ...prev[parent], [key]: value },
      }));
    } else {
      setInteractionMessage(prev => ({
        ...prev,
        [parent]: { ...prev[parent], [key]: value },
      }));
    }
  };

  const addField = () => {
    if (activeTab === 'replyFormat') {
      setReplyEmbed(prev => ({
        ...prev,
        fields: [...prev.fields, { name: 'Field Name', value: 'Field Value', inline: false }],
      }));
    } else {
      setInteractionMessage(prev => ({
        ...prev,
        fields: [...prev.fields, { name: 'Field Name', value: 'Field Value', inline: false }],
      }));
    }
  };

  const updateField = (index, newField) => {
    if (activeTab === 'replyFormat') {
      setReplyEmbed(prev => ({
        ...prev,
        fields: prev.fields.map((f, i) => (i === index ? newField : f)),
      }));
    } else {
      setInteractionMessage(prev => ({
        ...prev,
        fields: prev.fields.map((f, i) => (i === index ? newField : f)),
      }));
    }
  };

  const removeField = (index) => {
    if (activeTab === 'replyFormat') {
      setReplyEmbed(prev => ({
        ...prev,
        fields: prev.fields.filter((_, i) => i !== index),
      }));
    } else {
      setInteractionMessage(prev => ({
        ...prev,
        fields: prev.fields.filter((_, i) => i !== index),
      }));
    }
  };

  const generateOutput = () => {
    if (activeTab === 'replyFormat') {
      const cleanedEmbed = cleanObject(replyEmbed);
      const config = {
        discord: {
          reply_format: {
            embed: cleanedEmbed
          }
        }
      };
      
      const yamlString = yaml.dump(config, {
        indent: 2,
        lineWidth: -1,
        noReferenceQuotes: true,
        quotingType: '"'
      });

      setOutput(yamlString);
    } else {
      const cleanedInteractionMessage = cleanObject(interactionMessage);
      const jsonString = JSON.stringify({ embeds: [cleanedInteractionMessage] }, null, 2);
      setOutput(jsonString);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(output);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const activeEmbed = activeTab === 'replyFormat' ? replyEmbed : interactionMessage;

  return (
    <div className="min-h-screen bg-gray-100 py-6 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="bg-white shadow-xl rounded-lg overflow-hidden">
          <div className="px-6 py-8 bg-gradient-to-r from-[#2461f1] to-[#8044f6] sm:px-10 text-center">
            <h1 className="text-4xl font-bold text-white">ServerAssistantAI Discord Embed Creator</h1>
          </div>
          
          <div className="p-6">
            <div className="flex space-x-1 mb-4 bg-gray-100 p-1 rounded-lg">
              <button
                onClick={() => setActiveTab('replyFormat')}
                className={`flex-1 py-2 px-4 rounded-md transition-colors duration-200 ${
                  activeTab === 'replyFormat'
                    ? 'bg-[#0747ca] text-white'
                    : 'text-gray-500 hover:bg-gray-200'
                }`}
              >
                Discord Reply Format
              </button>
              <button
                onClick={() => setActiveTab('interactionMessage')}
                className={`flex-1 py-2 px-4 rounded-md transition-colors duration-200 ${
                  activeTab === 'interactionMessage'
                    ? 'bg-[#0747ca] text-white'
                    : 'text-gray-500 hover:bg-gray-200'
                }`}
              >
                Discord Interaction Message
              </button>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="space-y-4">
                <div>
                  <Label>Color</Label>
                  <div className="flex items-center space-x-2">
                    <Input
                      type="number"
                      value={activeEmbed.color}
                      onChange={(e) => updateEmbed('color', parseInt(e.target.value) || 0)}
                      placeholder="5814783"
                      className="flex-grow"
                    />
                    <input
                      type="color"
                      value={`#${activeEmbed.color.toString(16).padStart(6, '0')}`}
                      onChange={(e) => updateEmbed('color', parseInt(e.target.value.slice(1), 16))}
                      className="w-10 h-10 p-0 rounded"
                    />
                  </div>
                </div>

                <Card className="p-4">
                  <h3 className="font-semibold mb-2">Title and URL</h3>
                  <div className="space-y-2">
                    <Input
                      value={activeEmbed.title}
                      onChange={(e) => updateEmbed('title', e.target.value)}
                      placeholder="Embed Title"
                    />
                    <Input
                      value={activeEmbed.url}
                      onChange={(e) => updateEmbed('url', e.target.value)}
                      placeholder="Title URL"
                    />
                  </div>
                </Card>

                <Card className="p-4">
                  <h3 className="font-semibold mb-2">Author</h3>
                  <div className="space-y-2">
                    <Input
                      value={activeEmbed.author.name}
                      onChange={(e) => updateNestedEmbed('author', 'name', e.target.value)}
                      placeholder="Author Name"
                    />
                    <Input
                      value={activeEmbed.author.icon_url}
                      onChange={(e) => updateNestedEmbed('author', 'icon_url', e.target.value)}
                      placeholder="Author Icon URL"
                    />
                    <Input
                      value={activeEmbed.author.url}
                      onChange={(e) => updateNestedEmbed('author', 'url', e.target.value)}
                      placeholder="Author URL"
                    />
                  </div>
                </Card>

                <div>
                  <Label>Description</Label>
                  <Textarea
                    value={activeEmbed.description}
                    onChange={(e) => updateEmbed('description', e.target.value)}
                    placeholder="[message]"
                  />
                </div>

                <div>
                  <Label>Thumbnail URL</Label>
                  <Input
                    value={activeEmbed.thumbnail.url}
                    onChange={(e) => updateNestedEmbed('thumbnail', 'url', e.target.value)}
                    placeholder="https://example.com/thumbnail.png"
                  />
                </div>

                <Card className="p-4">
                  <h3 className="font-semibold mb-2">Fields</h3>
                  {activeEmbed.fields.map((field, index) => (
                    <FieldInput
                      key={index}
                      index={index}
                      field={field}
                      updateField={updateField}
                      removeField={removeField}
                    />
                  ))}
                  <Button onClick={addField} className="mt-2">Add Field</Button>
                </Card>

                <div>
                  <Label>Image URL</Label>
                  <Input
                    value={activeEmbed.image.url}
                    onChange={(e) => updateNestedEmbed('image', 'url', e.target.value)}
                    placeholder="https://example.com/image.png"
                  />
                </div>

                <Card className="p-4">
                  <h3 className="font-semibold mb-2">Footer</h3>
                  <div className="space-y-2">
                    <Input
                      value={activeEmbed.footer.text}
                      onChange={(e) => updateNestedEmbed('footer', 'text', e.target.value)}
                      placeholder="Footer Text"
                    />
                    <Input
                      value={activeEmbed.footer.icon_url}
                      onChange={(e) => updateNestedEmbed('footer', 'icon_url', e.target.value)}
                      placeholder="Footer Icon URL"
                    />
                  </div>
                </Card>

                <Button onClick={generateOutput} className="w-full">Generate Config</Button>
              </div>

              <div className="space-y-4">
                <h2 className="text-xl font-bold">Preview</h2>
                <div className="bg-[#313338] p-4 rounded-md">
                  <EmbedVisualizer 
                    key={previewKey} 
                    embed={{ embed: cleanObject(activeEmbed) }} 
                    onError={(e) => console.error("Error while parsing embed:", e)} 
                  />
                </div>

                {output && (
                  <div className="mt-4">
                    <h2 className="text-xl font-bold mb-2">Output Config</h2>
                    <SyntaxHighlighter 
                      language={activeTab === 'replyFormat' ? "yaml" : "json"} 
                      style={vscDarkPlus} 
                      className="rounded-md"
                    >
                      {output}
                    </SyntaxHighlighter>
                    <div className="mt-2 flex items-center">
                      <Button onClick={copyToClipboard} className="mr-2">
                        <Copy className="w-4 h-4 mr-2" /> Copy to Clipboard
                      </Button>
                      {copied && (
                        <span className="text-green-500 flex items-center">
                          <CheckCircle className="h-4 w-4 mr-1" /> Copied!
                        </span>
                      )}
                    </div>
                  </div>
                )}

                  <Alert>
                    <AlertCircle className="h-4 w-4 text-red-500" />
                    <AlertTitle className="!text-red-500">Note</AlertTitle>
                    <AlertDescription className="!text-red-500">
                      {activeTab === 'replyFormat' 
                        ? "This config uses [message] as a placeholder for the AI's response. Make sure to include it in your description or one of the fields. Currently, history doesn't work with embed message format."
                        : "This config uses placeholders like [player.name], [sent], [type], [question], and [response]. These will be replaced with actual data when used. Make sure to keep these placeholders in their respective fields for proper functionality."}
                    </AlertDescription>
                  </Alert>
              </div>
            </div>
          </div>
        </div>

        <footer className="mt-8 text-center text-sm text-gray-500">
          © 2024 <a href="https://code-solutions.dev/" className="text-indigo-600 hover:text-indigo-500">CodeSolutions</a>. All rights reserved. ServerAssistantAI is a product of CodeSolutions.
        </footer>
      </div>
    </div>
  );
};

export default DiscordEmbedCreator;