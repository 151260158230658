import React from 'react';
import DiscordEmbedCreator from './components/DiscordEmbedCreator';

function App() {
  return (
    <div className="min-h-screen p-8 bg-gray-100">
      <DiscordEmbedCreator />
    </div>
  );
}

export default App;